import React from "react";
import { observer } from "mobx-react-lite";

import { Fetchable, IProps as IFetchAbleProps } from "~/components/Fetchable";
import { EmptyList, IEmptyListProps } from "..";


interface IProps<DataType> extends IFetchAbleProps {
	item: DataType | null;
	renderItem: (item: DataType) => React.ReactElement;
	emptylistProps?: IEmptyListProps;
}


export const DetaislContent = observer(<DataType,>(props: IProps<DataType>) => {
	const { item, loading, loadingComponent, renderItem, emptylistProps } = props;

	return (
		<Fetchable loading={loading} loadingComponent={loadingComponent}>
			{
				item
					? renderItem(item)
					: <EmptyList {...emptylistProps} />
			}
		</Fetchable>
	);
});
