import { LoaderShelf, AbstractPaginatedListStore } from "@startapp/mobx-utils";

import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import api from "~/resources/api";

export default class Store extends AbstractPaginatedListStore<api.News> {

	public loader = new LoaderShelf();

	constructor() {
		super();
		this.fetchPage(0);
	}

	protected getDataItemsPerPage(page: number): Promise<api.News[]> {
		return api.getAllNews(page);
	}


	public deleteNews = async (id: string) => {
		this.loader.tryStart();
		try {

			const deletedNews = await api.deleteNews(id);

			showSuccessToast(strings.news.table.delete(deletedNews.title));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
