import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

export default class Store {
	private id: string;
	public loader = new LoaderShelf();
	public reservation = new AttributeShelf<api.Reservation | null>(null);

	constructor(id: string) {
		this.id = id;
		this.getReservation();
		makeAutoObservable(this);
	}

	private updateReservation = async () => {
		const reservation = await api.getReservation(this.id);
		this.reservation.setValue(reservation);
	};

	public getReservation = async () => {
		try {
			this.loader.tryStart(strings.error.stillLoading);
			await this.updateReservation();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public removeReservation = async (onSuccess?: () => void) => {
		try {
			this.loader.tryStart(strings.error.stillLoading);
			await api.removeProductReservation(this.id);
			showSuccessToast(strings.reservations.removeReservation.success);
			if (onSuccess) {
				onSuccess();
			}
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
