import { ComponentStyleConfig } from "@chakra-ui/react";

export const Badge: ComponentStyleConfig = {
	baseStyle: {
		borderTopRightRadius: 0,
		borderRadius: "lg",
	},
	defaultProps: {
		colorScheme: "primary",
	},
};
