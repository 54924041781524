import React from "react";
import { observer } from "mobx-react-lite";

import {
	ModalHeader,
	Modal as CModal,
	ModalOverlay,
	ModalContent,
	ModalBody,
} from "@chakra-ui/react";

export interface IProps  {
	isOpen: boolean;
	title?: string;
	closeOnOverlayClick?: boolean;
	description?: string;
	onClose: () => void;
}

export const Modal: React.FC<IProps> = observer((props) => {
	const {
		onClose,
		isOpen,
		title,
		closeOnOverlayClick,
		children,
	} = props;

	return (
		<CModal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={closeOnOverlayClick}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				w={{base:"90%", md: "initial"}}
			>
				<ModalHeader color="primary.500" textAlign="center">{title}</ModalHeader>
				<ModalBody color="primary.500">
					{children}
				</ModalBody>
			</ModalContent>
		</CModal>
	);

});

