import React from "react";
import { observer } from "mobx-react-lite";

import strings from "~/resources/strings";
import { Center, Text } from "@chakra-ui/react";
import { useAuthRoute } from "~/hooks/useAuthRoute";

const Home: React.FC = () => {
	useAuthRoute();

	return (
		<Center>
			<Text
				mt="8"
				color="secondary.500"
				fontSize={{ base: "4xl", sm: "5xl", lg: "6xl" }}
			>
				{strings.home.welcome}
			</Text>
		</Center>
	);
};

export default observer(Home);
