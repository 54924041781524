import { makeAutoObservable, runInAction } from "mobx";
import { LoaderShelf } from "@startapp/mobx-utils";
import { UploadedFile } from "./types";
import { craftImageBuffer, openFileInput } from "./fileUtils";

import api from "../../resources/api";

interface IOptions {
	initialImage?: api.Image;
}

export default class ImagePickerShelf {
	public localFile: File | null = null;
	public uploadedImage: UploadedFile | null = null;
	public errorMsg: string | null = null;
	public loader = new LoaderShelf();

	constructor(options?: IOptions) {
		if (options && options.initialImage) {
			this.uploadedImage = options.initialImage;
		}
		makeAutoObservable(this);
	}

	public get src(): string | null {
		return (
			(this.localFile && URL.createObjectURL(this.localFile)) ||
			(this.uploadedImage && this.uploadedImage.url)
		);
	}

	public get uncertainfiedImage(){
		if (this.uploadedImage) {
			return {
				image: this.uploadedImage,
				bytes: null,
			};
		}
		return null;
	}

	private setErrorMsg = (errorMsg: string | null) => {
		this.errorMsg = errorMsg;
	};

	private setUploadedImage = (uploadedImage: UploadedFile | null) => {
		this.uploadedImage = uploadedImage;
	};

	private setLocalFileAndCraftBuffer = (file: File | null) => {
		this.localFile = file;
		if (file) {
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			craftImageBuffer(file, 1000, this.upload);
		}
	};

	private onFileInputResolve = (file: File | null) => {
		this.setLocalFileAndCraftBuffer(file);
	};

	private pick = () => {
		openFileInput(this.onFileInputResolve, this.setErrorMsg, "image/*");
	};

	private upload = async (buffer: Buffer) => {
		this.loader.start();
		this.setErrorMsg(null);
		try {
			const image = await api.uploadImage(buffer, api.ImageFormat.jpeg);
			runInAction(() => {
				this.uploadedImage = image;
			});
		} catch (err) {
			// TODO - Show error message / treat error
			this.setErrorMsg(err?.message || err || "Error uploading");
		} finally {
			this.loader.end();
		}
	};

	public getPickerFields = () => ({
		src: this.src,
		pick: this.pick,
		setUploadedImage: this.setUploadedImage,
		uncertainfiedImage: this.uncertainfiedImage,
		loading: this.loader.isLoading,
	});
}
