import { LoaderShelf, AbstractPaginatedListStore } from "@startapp/mobx-utils";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import api from "~/resources/api";

export default class Store extends AbstractPaginatedListStore<api.Reservation> {

	public loader = new LoaderShelf();

	constructor() {
		super();
		this.fetchPage(0);
	}

	protected getDataItemsPerPage(page: number): Promise<api.Reservation[]> {
		return api.getAllReservations(page);
	}

	public removeReservation = async (reservationId: string) => {
		try {
			this.loader.tryStart(strings.error.stillLoading);
			await api.removeProductReservation(reservationId);
			await this.fetchPage(this.page);
			showSuccessToast(strings.reservations.removeReservation.success);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	}
}
