import React from "react";
import { Icon, Flex, Image, Box } from "@chakra-ui/react";
import { DrawerContainer, DrawerProfile } from "../../../components";
import { AiOutlineMenu, AiOutlineUser } from "react-icons/ai";
import { useGlobalStore } from "../../../contexts/useGlobalContext";

export const NavbarContent: React.FC = () => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [isOpenProfile, setIsOpenProfile] = React.useState<boolean>(false);

	const { authStore } = useGlobalStore();

	return (
		<>
			<Flex justifyContent="center" alignItems="center">
				<Icon
					as={AiOutlineMenu}
					onClick={() => setIsOpen(true)}
					cursor="pointer"
					color="primary.500"
					w={35}
					h={35}
				/>
			</Flex>
			<Image
				alt="logo"
				src="/logo.png"
				h={{base: "3.5rem", sm: "4.5rem"}}
			/>
			{authStore.currentAdminUser?.image ? (
				<Image
					borderRadius="full"
					src={authStore.currentAdminUser.image.url}
					w="45px"
					onClick={() => setIsOpenProfile(true)}
					cursor="pointer"
					h="45px"
				/>
			) : (
				<Box
					borderWidth={1}
					cursor="pointer"
					p={2}
					onClick={() => setIsOpenProfile(true)}
					bg="secondary.500"
					borderRadius="full"
				>
					<Icon
						as={AiOutlineUser}
						color="primary.500"
						w="40px"
						h="40px"
					/>
				</Box>
			)}
			<DrawerProfile
				isOpen={isOpenProfile}
				onClose={() => setIsOpenProfile(false)}
			/>
			<DrawerContainer
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</>
	);
};
