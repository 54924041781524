import { LoaderShelf, AbstractPaginatedListStore } from "@startapp/mobx-utils";

import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import api from "~/resources/api";

export default class Store extends AbstractPaginatedListStore<api.Tournament> {

	public loader = new LoaderShelf();

	constructor() {
		super();
		this.fetchPage(0);
	}

	protected getDataItemsPerPage(page: number): Promise<api.Tournament[]> {
		return api.getAllTournaments(page);
	}


	public deleteTournament = async (id: string) => {
		this.loader.tryStart();
		try {

			const deletedUser = await api.deleteTournament(id);

			showSuccessToast(strings.users.table.delete(deletedUser.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
