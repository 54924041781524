import React from "react";
import { observer } from "mobx-react-lite";
import {
	Box,
	BoxProps,
	Input,
	InputProps,
	FormErrorMessage,
	FormControl,
} from "@chakra-ui/react";
import { Label } from "../";
import spacing from "../../themes/spacing";
import InputMask from "react-input-mask";

interface IProps extends Omit<InputProps, "value"> {
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	labelText?: string;
	value?: string;
	helper?: string;
	boxProps?: BoxProps;
	mask?: string;
	errorText?: string | null;
}

export const TextInput: React.FC<IProps> = observer((props) => {
	const {
		onChange,
		labelText,
		helper,
		boxProps,
		value,
		mask,
		errorText,
		...rest
	} = props;

	return (
		<Box {...boxProps}>
			<FormControl isInvalid={!!errorText} position="relative">
				<Label marginBottom={spacing.s1} helper={helper}>{labelText}</Label>
				<Input
					onChange={onChange}
					value={value}
					as={InputMask}
					mask={mask}
					borderWidth={1}
					borderColor={errorText ? "red.500" : undefined}
					error
					{...rest}
				/>
				<FormErrorMessage
					position="absolute"
					bottom={-6}
				>
					{errorText}
				</FormErrorMessage>
			</FormControl>
		</Box>
	);
});
