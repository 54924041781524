import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Stack,
	Button,
	Center,
	Box,
	Textarea,
} from "@chakra-ui/react";
import {
	DatePicker,
	Section,
	TextInput,
	ImagePicker,
	Label,
} from "~/components";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";
import Store from "./store";

interface IParams {
	id?: string;
}

const CreateOrEdit: React.FC = () => {
	const pageStrings = strings.news.createOrEdit;
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const onSuccess = () => {
		history.goBack();
	};

	const store = useLocalObservable(() => new Store(id));

	const onSubmit = () => {
		store.createOrEditNews(onSuccess);
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			p={{ base: 0, sm: 16 }}
		>
			<Section title={id ? pageStrings.edit.title : pageStrings.create.title}>
				<Stack w="100%" spacing={spacing.s8}>
					<Center>
						<ImagePicker pickImage={store.imageShelf.getPickerFields().pick} src={store.imageShelf.src} />
					</Center>
					<TextInput
						labelText={commonStrings.fields.title}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("title")}
						{...store.formShelf.field("title")}
					/>
					<DatePicker
						label={commonStrings.fields.date}
						selectedDate={store.date.value}
						onChangeDate={store.date.setValue}
						isDisabled={store.loader.isLoading}
					/>
					<Box>
						<Label mb={spacing.s2}>{commonStrings.fields.text}</Label>
						<Textarea
							variant="filled"
							type="text"
							minH="2xs"
							isDisabled={store.loader.isLoading}
							{...store.formShelf.field("text")}
						/>
					</Box>
					<Button
						isLoading={store.loader.isLoading}
						onClick={onSubmit}
					>
						{pageStrings.confirmButton(!!id)}
					</Button>
				</Stack>
			</Section>
		</Flex>
	);
};

export default observer(CreateOrEdit);
