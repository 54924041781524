import { ComponentStyleConfig } from "@chakra-ui/react";

export const Text: ComponentStyleConfig = {
	defaultProps: {
		variant: "primary",
		size: "sm",
	},
	sizes: {
		xs: {
			fontSize: "xs",
		},
		sm: {
			fontSize: "sm",
			fontWeight: 400,
		},
		md: {
			fontSize: "md",
		},
	},
	variants: {
		primary: {
			color: "primary.700",
		},
		secondary: {
			color: "primary.500",
		},
	},
};
