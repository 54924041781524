import { makeAutoObservable } from "mobx";
import { AttributeShelf, FormShelf, LoaderShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import ImagePickerShelf from "~/shelves/ImagePicker";
import strings from "~/resources/strings";

const pageStrings = strings.tournaments.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		name: "",
		lineup: "",
		topScorer: "",
		bestDefense: "",
	});
	public playerFormShelf = new FormShelf({
		pĺayer: "",
	});

	public loader = new LoaderShelf();
	public imageShelf = new ImagePickerShelf();
	public gamesImageShelf = new ImagePickerShelf();

	public date = new AttributeShelf(new Date());
	public type = new AttributeShelf(api.TournamentType.internal);
	public ranking = new AttributeShelf<string[]>([]);
	public currentPlayerEditing = new AttributeShelf<number | null>(null);

	public id?: string;

	constructor(id?: string) {

		if (id) {
			this.id = id;
			this.getTournament(id);
		}
		makeAutoObservable(this);
	}

	public getTournament = async (id: string) => {
		this.loader.tryStart();
		try {
			const tournament = await api.getTournament(id);
			this.setInitValues(tournament);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (tournament: api.Tournament) => {
		this.formShelf = new FormShelf({
			name: tournament.name,
			lineup: tournament.lineup,
			topScorer: tournament.topScorer || "",
			bestDefense: tournament.bestDefense || "",
		});
		if (tournament.image) {
			this.imageShelf.uploadedImage = tournament.image;
		}
		if (tournament.gamesImage) {
			this.gamesImageShelf.uploadedImage = tournament.gamesImage;
		}
		this.date.setValue(tournament.date);
		this.ranking.setValue(tournament.ranking);
		this.type.setValue(tournament.type);
	};

	public pushPlayer = () => {
		this.ranking.value.push(this.playerFormShelf.getValue("pĺayer"));
		this.playerFormShelf = new FormShelf({
			pĺayer: "",
		});
	}

	public setEditingPlayer = () => {

		const playerIndex = this.currentPlayerEditing.value;

		if (playerIndex !== null) {
			this.playerFormShelf = new FormShelf({
				pĺayer: this.ranking.value[playerIndex],
			});
		}
	}

	public clear = () => {
		this.currentPlayerEditing.setValue(null);
		this.playerFormShelf = new FormShelf({
			pĺayer: "",
		});
	}
	public editPlayer = () => {
		const playerIndex = this.currentPlayerEditing.value;
		if (playerIndex !== null) {

			this.ranking.value[playerIndex] = this.playerFormShelf.getValue("pĺayer");

			this.clear();
		}
	}

	public deletePlayer = (playerIndex: number) => {
		this.ranking.value = this.ranking.value.filter((value, index) => (index !== playerIndex));
	}

	public createOrEditTournament = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.formShelf.getValues();
			const {
				name,
				lineup,
				topScorer,
				bestDefense,
			} = data;

			if (this.id) {

				await api.editTournament(this.id, {
					image: this.imageShelf.uncertainfiedImage,
					gamesImage: this.gamesImageShelf.uncertainfiedImage,
					name,
					lineup,
					topScorer,
					bestDefense,
					date: this.date.value,
					ranking: this.ranking.value,
					type: this.type.value,
				});

				showSuccessToast(pageStrings.edit.success);
			} else {
				await api.createTournament({
					image: this.imageShelf.uncertainfiedImage,
					gamesImage: this.gamesImageShelf.uncertainfiedImage,
					name,
					lineup,
					topScorer: topScorer || null,
					bestDefense: bestDefense || null,
					date: this.date.value,
					ranking: this.ranking.value,
					type: this.type.value,
				});

				showSuccessToast(pageStrings.create.success);
			}

			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
