import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import Users from "./Users";
import Tournaments from "./Tournaments";
import Events from "./Events";
import Workouts from "./Workouts";
import News from "./News";
import Products from "./Products";
import Reservations from "./Reservations";
import { MainLayout } from "../../layout";
import { useAuthRoute } from "../../hooks/useAuthRoute";

const Dashboard: React.FC = () => {
	useAuthRoute();
	return (
		<MainLayout >
			<Switch>
				<Route
					exact
					path="/dashboard"
					component={Home}
				/>
				<Route path="/dashboard/users" component={Users} />
				<Route path="/dashboard/tournaments" component={Tournaments} />
				<Route path="/dashboard/events" component={Events} />
				<Route path="/dashboard/workouts" component={Workouts} />
				<Route path="/dashboard/news" component={News} />
				<Route path="/dashboard/products" component={Products} />
				<Route path="/dashboard/reservations" component={Reservations} />
			</Switch>
		</MainLayout>
	);
};

export default observer(Dashboard);
