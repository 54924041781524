import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Stack, Button, Center } from "@chakra-ui/react";

import {
	ImagePicker,
	Section,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";
import Store from "./store";

interface IParams {
	id?: string;
}

const CreateOrEdit: React.FC = () => {
	const pageStrings = strings.users.createOrEdit;
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const onSuccess = () => {
		history.goBack();
	};

	const store = useLocalObservable(() => new Store(id));

	const onSubmit = () => {
		store.createOrEditUser(onSuccess);
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			p={{base: 0, sm: 16 }}
		>
			<Section title={id ? pageStrings.edit.title : pageStrings.create.title}>
				<Stack w="100%" spacing={spacing.s8}>
					<Center>
						<ImagePicker pickImage={store.imageShelf.getPickerFields().pick} src={store.imageShelf.src} />
					</Center>
					<TextInput
						labelText={commonStrings.fields.name}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("name")}
						{...store.formShelf.field("name")}
					/>
					<TextInput
						labelText={commonStrings.fields.email}
						type="email"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("email")}
						{...store.formShelf.field("email")}
					/>
					<TextInput
						labelText={commonStrings.fields.phone}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("phone")}
						mask="99 99999-9999"
						{...store.formShelf.field("phone")}
					/>
					<TextInput
						labelText={commonStrings.fields.cpf}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("cpf")}
						mask="999.999.999-99"
						{...store.formShelf.field("cpf")}
					/>
					{!id && (
						<TextInput
							labelText={commonStrings.fields.password}
							type="password"
							isDisabled={store.loader.isLoading}
							errorText={store.formShelf.fieldError.getFieldError("password")}
							{...store.formShelf.field("password")}
						/>
					)}
					<Button
						isLoading={store.loader.isLoading}
						onClick={onSubmit}
					>
						{pageStrings.confirmButton(!!id)}
					</Button>
				</Stack>
			</Section>
		</Flex>
	);
};

export default observer(CreateOrEdit);
