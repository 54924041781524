import { makeAutoObservable } from "mobx";
import { AttributeShelf, FormShelf, LoaderShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import ImagePickerShelf from "~/shelves/ImagePicker";
import strings from "~/resources/strings";

const pageStrings = strings.events.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		title: "",
	});
	public urlFormShelf = new FormShelf({
		url: "",
	});

	public loader = new LoaderShelf();
	public firstImage = new ImagePickerShelf();
	public secondImage = new ImagePickerShelf();
	public thirdImage = new ImagePickerShelf();
	public fourthImage = new ImagePickerShelf();

	public date = new AttributeShelf(new Date());
	public videosUrl = new AttributeShelf<string[]>([]);

	public id?: string;

	constructor(id?: string) {

		if (id) {
			this.id = id;
			this.getEvent(id);
		}
		makeAutoObservable(this);
	}

	public getEvent = async (id: string) => {
		this.loader.tryStart();
		try {
			const event = await api.getEvent(id);
			this.setInitValues(event);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (event: api.Event) => {
		this.formShelf = new FormShelf({
			title: event.title,
		});
		this.date.setValue(event.date);
		this.videosUrl.setValue(event.videoUrls);
		if (event.images.length > 0){
			this.firstImage.getPickerFields().setUploadedImage(event.images[0]);
		}
		if (event.images.length > 1){
			this.secondImage.getPickerFields().setUploadedImage(event.images[1]);
		}
		if (event.images.length > 2){
			this.thirdImage.getPickerFields().setUploadedImage(event.images[2]);
		}
		if (event.images.length > 3){
			this.fourthImage.getPickerFields().setUploadedImage(event.images[3]);
		}
	};

	public get imagesToPicker() {
		return [
			this.firstImage.getPickerFields(),
			this.secondImage.getPickerFields(),
			this.thirdImage.getPickerFields(),
			this.fourthImage.getPickerFields(),
		];
	}

	public get uploadedImages() {
		const images: api.UncertainImage[] = [];
		const imagePickers = [
			this.firstImage,
			this.secondImage,
			this.thirdImage,
			this.fourthImage,
		];
		imagePickers.forEach((imagePicker) => {
			if (imagePicker.uncertainfiedImage) {
				images.push(imagePicker.uncertainfiedImage);
			}
		});
		return images;
	}

	public deleteImage = (imageIndex: number) => {
		if (imageIndex === 0){
			this.firstImage = new ImagePickerShelf();
		}
		if (imageIndex === 1){
			this.secondImage = new ImagePickerShelf();
		}
		if (imageIndex === 2){
			this.thirdImage = new ImagePickerShelf();
		}
		if (imageIndex === 3){
			this.fourthImage = new ImagePickerShelf();
		}
	}

	public pushUrl = () => {
		this.videosUrl.value.push(this.urlFormShelf.getValue("url"));
		this.clear();
	}

	public clear = () => {
		this.urlFormShelf = new FormShelf({
			url: "",
		});
	}

	public deleteUrl = (urlIndex: number) => {
		this.videosUrl.value = this.videosUrl.value.filter((value, index) => (index !== urlIndex));
	}

	public createOrEditEvent= async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.formShelf.getValues();
			const { title } = data;

			if (this.id) {

				await api.editEvent(this.id, {
					images: this.uploadedImages,
					title,
					date: this.date.value,
					videoUrls: this.videosUrl.value,
				});

				showSuccessToast(pageStrings.edit.success);
			} else {
				await api.createEvent({
					images: this.uploadedImages,
					title,
					date: this.date.value,
					videoUrls: this.videosUrl.value,
				});

				showSuccessToast(pageStrings.create.success);
			}

			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
