import { Input } from "./Input";
import { Modal } from "./Modal";
import { Heading } from "./Heading";
import { Switch } from "./Switch";
import { Text } from "./Text";
import { Button } from "./Button";
import { Card } from "./Card";
import { Badge } from "./Badge";

export const components = {
	Input,
	Modal,
	Heading,
	Switch,
	Text,
	Button,
	Card,
	Badge,
};
