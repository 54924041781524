import React from "react";
import { observer } from "mobx-react-lite";
import {
	Button,
	HStack,
	List,
	ListItem,
	Text,
	IconButton,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Label } from "..";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";

interface IProps{
	title: string;
	items: string[];
	isLoading: boolean;
	onAdd: () => void;
	onDelete: (urlIndex: number) => void;
}

export const ItemList: React.FC<IProps> = observer((props) => {
	const {
		title,
		items,
		isLoading,
		onAdd,
		onDelete,
	} = props;

	return (
		<List spacing={3}>
			<ListItem>
				<Label>{title}</Label>
			</ListItem>
			{items.map((url,index)=>(
				<ListItem key={`${url}${index}`}>
					<HStack space={spacing.s2}>
						<Text fontSize="md" color="gray.500" flexGrow={1}>{url}</Text>
						<IconButton
							variant="icon"
							colorScheme="secondary"
							aria-label="Delete"
							size="sm"
							icon={<DeleteIcon />}
							onClick={()=>onDelete(index)}
						/>
					</HStack>
				</ListItem>
			))}
			<ListItem>
				<Button
					isLoading={isLoading}
					onClick={onAdd}
					size="sm"
					colorScheme="green"
				>
					{strings.actions.add}
				</Button>
			</ListItem>
		</List>
	);
});
