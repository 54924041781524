import React from "react";
import { Box, Stack, Text, Textarea } from "@chakra-ui/react";

export interface IDetailsRowProps {
	label: string;
	value: string;
	isLargeText?: boolean;
}

export const DetailsRow: React.FC<IDetailsRowProps> = (props) => {
	const { value, label, isLargeText } = props;
	return (
		<Box bg="gray.100" p={4} borderRadius="lg">
			<Stack spacing={1}>
				<Text fontSize="xl" fontWeight="bold">{label}</Text>
				{isLargeText ? (
					<Textarea
						variant="filled"
						type="text"
						readOnly
						value={value}
						minH={300}
					/>
				):(
					<Text fontSize="md" >{value}</Text>
				)}
			</Stack>
		</Box>
	);
};
