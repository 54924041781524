import { makeAutoObservable } from "mobx";
import { AttributeShelf, FormShelf, LoaderShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.workouts.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		title: "",
		description: "",
		url: "",
	});

	public loader = new LoaderShelf();
	public createdAt = new AttributeShelf(new Date());
	public id?: string;

	constructor(id?: string) {

		if (id) {
			this.id = id;
			this.getWorkout(id);
		}
		makeAutoObservable(this);
	}

	public getWorkout = async (id: string) => {
		this.loader.tryStart();
		try {
			const workout = await api.getWorkout(id);
			this.setInitValues(workout);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (workout: api.Workout) => {
		this.formShelf = new FormShelf({
			title: workout.title,
			description: workout.description,
			url: workout.url,
		});
		this.createdAt.setValue(workout.createdAt);
	};

	public createOrEditWorkout = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.formShelf.getValues();
			const {
				title,
				description,
				url,
			} = data;

			if (this.id) {

				await api.editWorkout(this.id, {
					title,
					description,
					url,
				});

				showSuccessToast(pageStrings.edit.success);
			} else {
				await api.createWorkout({
					title,
					description,
					url,
				});

				showSuccessToast(pageStrings.create.success);
			}

			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
