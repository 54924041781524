import React from "react";
import {
	Flex,
	TextProps,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { Label } from "..";

export interface IProps extends TextProps{
	text?: string;
}
export const EmptyList: React.FC<IProps> = (props) => {
	const { text, ...rest} = props;
	return (
		<Flex justifyContent="center">
			<Label {...rest} textAlign="center">{text || strings.common.noResutls}</Label>
		</Flex>
	);
};
