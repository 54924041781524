import {
	AiOutlineHome,
	AiOutlineUser,
	AiOutlineTrophy,
	AiOutlineCalendar,
	AiOutlineAudit,
} from "react-icons/ai";
import {
	BiNews,
	BiPackage,
	BiCart,
} from "react-icons/bi";
import strings from "../resources/strings";

const useMainRoutes = () => {
	const commonRoutes = [
		{
			Icon: AiOutlineHome,
			text: strings.nav.home,
			path: "/dashboard",
		},
		{
			Icon: AiOutlineUser,
			text: strings.nav.users,
			path: "/dashboard/users",
		},
		{
			Icon: AiOutlineTrophy,
			text: strings.nav.tournaments,
			path: "/dashboard/tournaments",
		},
		{
			Icon: AiOutlineCalendar,
			text: strings.nav.events,
			path: "/dashboard/events",
		},
		{
			Icon: AiOutlineAudit,
			text: strings.nav.workouts,
			path: "/dashboard/workouts",
		},
		{
			Icon: BiNews,
			text: strings.nav.news,
			path: "/dashboard/news",
		},
		{
			Icon: BiPackage,
			text: strings.nav.products,
			path: "/dashboard/products",
		},
		{
			Icon: BiCart,
			text: strings.nav.reservations,
			path: "/dashboard/reservations",
		},
	];

	return commonRoutes;
};

export default useMainRoutes;
