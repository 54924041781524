import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";
import spacing from "../../../themes/spacing";

export const Button: ComponentStyleConfig = {
	baseStyle: {
		color: "white",
		fontWeight: "bold",
		borderTopRightRadius: "0px",
		borderRadius: "md",
	},
	defaultProps: {
		variant: "solid",
		colorScheme: "primary",
	},
	variants: {
		outline: (props) => ({
			...theme.components.Button.variants.outline(props),
			borderWidth: "1px",
		}),
		link: ({ colorScheme}) => ({
			bg: "white",
			color: `${colorScheme}.500`,
			textDecoration: "underline",
			fontSize: "sm",
		}),
		larger: ({ colorScheme}) => ({
			bg: `${colorScheme}.500`,
			fontSize: "xl",
			h: "45px",
		}),
		footer: ({ colorScheme}) => ({
			bg: `${colorScheme}.500`,
			w: "full",
			h: "full",
			fontSize: "xl",
			paddingY: "4",
			borderTopRadius: "0",
			borderBottomRadius: { base: 0, sm: spacing.s4 },
		}),
		icon: ({ colorScheme}) => ({
			bg: "white",
			m: spacing.s1,
			_hover: {
				bg: `${colorScheme}.50`,
			},
			color: `${colorScheme}.500`,
		}),
	},
};
