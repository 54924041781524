import { makeAutoObservable } from "mobx";
import { FormShelf, LoaderShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import ImagePickerShelf from "~/shelves/ImagePicker";
import strings from "~/resources/strings";

const pageStrings = strings.users.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		email: "",
		name: "",
		phone: "",
		cpf: "",
		password: "",
	});

	public loader = new LoaderShelf();
    public imageShelf = new ImagePickerShelf();

	public id?: string;

	constructor(id?: string) {
		makeAutoObservable(this);

		if (id) {
			this.id = id;
			this.getUser(id);
		}
	}

	public getUser = async (id: string) => {
		this.loader.tryStart();
		try {
			const user = await api.getUser(id);
			this.setInitValues(user);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (user: api.User) => {
		this.formShelf = new FormShelf({
			email: user.email,
			name: user.name,
			phone: user.phone || "",
			cpf: user.cpf || "",
			password: "",
		});
		if (user.image){
			this.imageShelf.uploadedImage = user.image;
		}
	};

	public createOrEditUser = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.formShelf.getValues();
			const {
				email,
				name,
				phone,
				cpf,
				password,
			} = data;

			if (this.id){

				await api.editUser(this.id,{
					image: this.imageShelf.uncertainfiedImage,
					email,
					name,
					phone,
					cpf,
				});
			} else {
				await api.createUser({
					image: this.imageShelf.uncertainfiedImage,
					email,
					name,
					phone,
					cpf,
					password,
				});
			}

			showSuccessToast(pageStrings.create.success);
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
