import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Stack,
	Button,
	Center,
	HStack,
	useDisclosure,
} from "@chakra-ui/react";
import {
	DatePicker,
	Section,
	TextInput,
	Modal,
	MultipleImagePickers,
	ItemList,
} from "~/components";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";
import Store from "./store";

interface IParams {
	id?: string;
}

const CreateOrEdit: React.FC = () => {
	const pageStrings = strings.events.createOrEdit;
	const commonStrings = strings.common;

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { id } = useParams<IParams>();
	const history = useHistory();

	const onSuccess = () => {
		history.goBack();
	};

	const onCloseAndClear = () => {
		store.clear();
		onClose();
	};

	const store = useLocalObservable(() => new Store(id));

	const onSubmit = () => {
		store.createOrEditEvent(onSuccess);
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			p={{ base: 0, sm: 16 }}
		>
			<Section title={id ? pageStrings.edit.title : pageStrings.create.title}>
				<Stack w="100%" spacing={spacing.s8}>
					<Center>
						<MultipleImagePickers
							images={store.imagesToPicker}
							onDelete={store.deleteImage}
						/>
					</Center>
					<TextInput
						labelText={commonStrings.fields.title}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("title")}
						{...store.formShelf.field("title")}
					/>
					<DatePicker
						label={commonStrings.fields.date}
						selectedDate={store.date.value}
						onChangeDate={store.date.setValue}
						isDisabled={store.loader.isLoading}
					/>
					<ItemList
						title={pageStrings.listTitle}
						isLoading={store.loader.isLoading}
						items={store.videosUrl.value}
						onAdd={onOpen}
						onDelete={store.deleteUrl}
					/>
					<Modal
						isOpen={isOpen}
						onClose={onCloseAndClear}
						title={pageStrings.create.modal.title}
					>
						<Flex flexDir="column" alignItems="center">
							<TextInput
								type="text"
								isDisabled={store.loader.isLoading}
								errorText={store.urlFormShelf.fieldError.getFieldError("url")}
								{...store.urlFormShelf.field("url")}
							/>
							<HStack mt={spacing.s5}>
								<Button
									isLoading={store.loader.isLoading}
									onClick={() => {
										store.pushUrl();
										onCloseAndClear();
									}}
									colorScheme="green"
								>
									{strings.actions.add}
								</Button>
								<Button
									isLoading={store.loader.isLoading}
									onClick={() => {
										onCloseAndClear();
									}}
									bgColor="gray.400"
								>
									{strings.actions.back}
								</Button>
							</HStack>
						</Flex>
					</Modal>
					<Button
						isLoading={store.loader.isLoading}
						onClick={onSubmit}
					>
						{pageStrings.confirmButton(!!id)}
					</Button>
				</Stack>
			</Section>
		</Flex>
	);
};

export default observer(CreateOrEdit);
