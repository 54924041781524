import React from "react";
import { Text, FormControl, FormControlProps } from "@chakra-ui/react";
import { Label } from "..";
import spacing from "~/themes/spacing";

interface IProps extends FormControlProps{
	textError?: string;
	label: string;
}

export const FormError: React.FC<IProps> = (props) =>{
	const {children, textError, label, ...rest } = props;
	return (
		<FormControl {...rest}>
			<Label marginBottom={spacing.s1} >{label}</Label>
			{children}
			{textError && (
				<Text color="red.500" size="small">{textError}</Text>
			)}
		</FormControl>
	);
};
