import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Text, Tr } from "@chakra-ui/react";
import { Table, TableCellWithActionButtons } from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "react-router-dom";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import API from "~/resources/api";
import Store from "./store";
import api from "~/resources/api";
import moment from "moment";

const TableView: React.FC = () => {
	const pageStrings = strings.tournaments.table;
	const modal = strings.common.modal;
	const store = useLocalObservable(() => new Store());
	const { dialog } = useGlobalStore();

	const history = useHistory();

	const onDeleteTournament = (id: string) => {
		store.deleteTournament(id);
		dialog.closeDialog();
	};

	const onGoToEditTournament = (id: string) => history.push(`tournaments/edit/${id}`);

	const onGoToCreateTournament = () => history.push("tournaments/create/");

	const openDialog = (tournament: API.Tournament) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(tournament.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteTournament(tournament.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex p={{ base: "2", lg: "16"}}>
			<Table
				data={store._items}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				onAdd={onGoToCreateTournament}
				renderRow={(item) => (
					<Tr key={item.id} >
						<Td>
							<Text>{item.name}</Text>
						</Td>
						<Td>
							<Text>{api.translateTournamentType(item.type)}</Text>
						</Td>
						<Td>
							<Text>{moment(item.date).format("l")}</Text>
						</Td>
						<TableCellWithActionButtons
							onEdit={() => onGoToEditTournament(item.id)}
							onDelete={() => openDialog(item)}
						/>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				hasNextPage={store._isHaveNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
