import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface AdminUser {
    id: string;
    name: string;
    image: Image | null;
    email: string;
    createdAt: Date;
}

export interface EditAdminUser {
    name: string;
    email: string;
    image: UncertainImage | null;
}

export interface NewAdminUser {
    password: string;
    name: string;
    email: string;
    image: UncertainImage | null;
}

export interface EventDetails {
    title: string;
    date: Date;
    videoUrls: string[];
}

export interface NewEvent {
    images: UncertainImage[];
    title: string;
    date: Date;
    videoUrls: string[];
}

export interface EditEvent {
    images: UncertainImage[];
    title: string;
    date: Date;
    videoUrls: string[];
}

export interface Event {
    id: string;
    images: Image[];
    createdAt: Date;
    title: string;
    date: Date;
    videoUrls: string[];
}

export interface NewsDetails {
    title: string;
    text: string;
    date: Date;
}

export interface CreateNews {
    image: UncertainImage | null;
    title: string;
    text: string;
    date: Date;
}

export interface EditNews {
    image: UncertainImage | null;
    title: string;
    text: string;
    date: Date;
}

export interface News {
    id: string;
    image: Image | null;
    createdAt: Date;
    title: string;
    text: string;
    date: Date;
}

export interface ProductDetails {
    title: string;
    description: string;
    price: number;
    sizes: string[];
    isAvailable: boolean;
}

export interface NewProduct {
    images: UncertainImage[];
    title: string;
    description: string;
    price: number;
    sizes: string[];
    isAvailable: boolean;
}

export interface EditProduct {
    images: UncertainImage[];
    title: string;
    description: string;
    price: number;
    sizes: string[];
    isAvailable: boolean;
}

export interface Product {
    id: string;
    images: Image[];
    createdAt: Date;
    title: string;
    description: string;
    price: number;
    sizes: string[];
    isAvailable: boolean;
}

export interface UserDetails {
    email: string;
    name: string;
    phone: string | null;
    cpf: string | null;
}

export interface NewUser {
    image: UncertainImage | null;
    password: string;
    email: string;
    name: string;
    phone: string | null;
    cpf: string | null;
}

export interface EditUser {
    image: UncertainImage | null;
    email: string;
    name: string;
    phone: string | null;
    cpf: string | null;
}

export interface User {
    id: string;
    image: Image | null;
    createdAt: Date;
    email: string;
    name: string;
    phone: string | null;
    cpf: string | null;
}

export interface Reservation {
    id: string;
    user: User;
    product: Product;
    status: ReservationStatus;
    size: string;
    removedAt: Date | null;
    createdAt: Date;
}

export interface TournamentDetails {
    name: string;
    date: Date;
    lineup: string;
    type: TournamentType;
    topScorer: string | null;
    bestDefense: string | null;
    ranking: string[];
}

export interface NewTournament {
    image: UncertainImage | null;
    gamesImage: UncertainImage | null;
    name: string;
    date: Date;
    lineup: string;
    type: TournamentType;
    topScorer: string | null;
    bestDefense: string | null;
    ranking: string[];
}

export interface EditTournament {
    image: UncertainImage | null;
    gamesImage: UncertainImage | null;
    name: string;
    date: Date;
    lineup: string;
    type: TournamentType;
    topScorer: string | null;
    bestDefense: string | null;
    ranking: string[];
}

export interface Tournament {
    id: string;
    image: Image | null;
    gamesImage: Image | null;
    createdAt: Date;
    name: string;
    date: Date;
    lineup: string;
    type: TournamentType;
    topScorer: string | null;
    bestDefense: string | null;
    ranking: string[];
}

export interface WorkoutDetails {
    title: string;
    description: string;
    url: string;
}

export interface NewWorkout {
    title: string;
    description: string;
    url: string;
}

export interface EditWorkout {
    title: string;
    description: string;
    url: string;
}

export interface Workout {
    id: string;
    createdAt: Date;
    title: string;
    description: string;
    url: string;
}

export enum TournamentType {
    internal = "internal",
    external = "external",
}

export function translateTournamentType(enumTournamentType: TournamentType): string {
    switch (enumTournamentType) {
        case TournamentType.internal: {
            return strings ? strings["enum"]["TournamentType"]["internal"] || TournamentType.internal : TournamentType.internal;
        }
        case TournamentType.external: {
            return strings ? strings["enum"]["TournamentType"]["external"] || TournamentType.external : TournamentType.external;
        }
    }
    return "";
}

export function allValuesTournamentType(): TournamentType[] {
    return [
        TournamentType.internal,
        TournamentType.external,
    ];
}

export function allTranslatedValuesTournamentType(): string[] {
    return [
        translateTournamentType(TournamentType.internal),
        translateTournamentType(TournamentType.external),
    ];
}

export function allDisplayableValuesTournamentType(): string[] {
    return allTranslatedValuesTournamentType().sort();
}

export function valueFromTranslationTournamentType(translation: string): TournamentType {
    const index = allTranslatedValuesTournamentType().indexOf(translation);
    return allValuesTournamentType()[index] || TournamentType.internal;
}

export enum ReservationStatus {
    active = "active",
    removed = "removed",
    canceledByUser = "canceledByUser",
}

export function translateReservationStatus(enumReservationStatus: ReservationStatus): string {
    switch (enumReservationStatus) {
        case ReservationStatus.active: {
            return strings ? strings["enum"]["ReservationStatus"]["active"] || ReservationStatus.active : ReservationStatus.active;
        }
        case ReservationStatus.removed: {
            return strings ? strings["enum"]["ReservationStatus"]["removed"] || ReservationStatus.removed : ReservationStatus.removed;
        }
        case ReservationStatus.canceledByUser: {
            return strings ? strings["enum"]["ReservationStatus"]["canceledByUser"] || ReservationStatus.canceledByUser : ReservationStatus.canceledByUser;
        }
    }
    return "";
}

export function allValuesReservationStatus(): ReservationStatus[] {
    return [
        ReservationStatus.active,
        ReservationStatus.removed,
        ReservationStatus.canceledByUser,
    ];
}

export function allTranslatedValuesReservationStatus(): string[] {
    return [
        translateReservationStatus(ReservationStatus.active),
        translateReservationStatus(ReservationStatus.removed),
        translateReservationStatus(ReservationStatus.canceledByUser),
    ];
}

export function allDisplayableValuesReservationStatus(): string[] {
    return allTranslatedValuesReservationStatus().sort();
}

export function valueFromTranslationReservationStatus(translation: string): ReservationStatus {
    const index = allTranslatedValuesReservationStatus().indexOf(translation);
    return allValuesReservationStatus()[index] || ReservationStatus.active;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    InvalidOperation = "InvalidOperation",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AlreadyRegistered = "AlreadyRegistered",
    AccessNotAllowed = "AccessNotAllowed",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    CPFAlreadyRegistered = "CPFAlreadyRegistered",
    NotLoggedIn = "NotLoggedIn",
    DynamicLinkError = "DynamicLinkError",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.InvalidOperation: {
            return strings ? strings["enum"]["ErrorType"]["InvalidOperation"] || ErrorType.InvalidOperation : ErrorType.InvalidOperation;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.CPFAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CPFAlreadyRegistered"] || ErrorType.CPFAlreadyRegistered : ErrorType.CPFAlreadyRegistered;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.DynamicLinkError: {
            return strings ? strings["enum"]["ErrorType"]["DynamicLinkError"] || ErrorType.DynamicLinkError : ErrorType.DynamicLinkError;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.InvalidOperation,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AlreadyRegistered,
        ErrorType.AccessNotAllowed,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.CPFAlreadyRegistered,
        ErrorType.NotLoggedIn,
        ErrorType.DynamicLinkError,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.InvalidOperation),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.CPFAlreadyRegistered),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.DynamicLinkError),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            name: newAdminUser.name,
            email: newAdminUser.email,
            image: newAdminUser.image === null || newAdminUser.image === undefined ? null : {
                bytes: newAdminUser.image.bytes === null || newAdminUser.image.bytes === undefined ? null : newAdminUser.image.bytes.toString("base64"),
                image: newAdminUser.image.image === null || newAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: newAdminUser.image.image.thumb.width || 0,
                        height: newAdminUser.image.image.thumb.height || 0,
                        url: newAdminUser.image.image.thumb.url,
                    },
                    width: newAdminUser.image.image.width || 0,
                    height: newAdminUser.image.image.height || 0,
                    url: newAdminUser.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function editCurrentAdminUser(editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
            image: editAdminUser.image === null || editAdminUser.image === undefined ? null : {
                bytes: editAdminUser.image.bytes === null || editAdminUser.image.bytes === undefined ? null : editAdminUser.image.bytes.toString("base64"),
                image: editAdminUser.image.image === null || editAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: editAdminUser.image.image.thumb.width || 0,
                        height: editAdminUser.image.image.thumb.height || 0,
                        url: editAdminUser.image.image.thumb.url,
                    },
                    width: editAdminUser.image.image.width || 0,
                    height: editAdminUser.image.image.height || 0,
                    url: editAdminUser.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editCurrentAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function editAdminUser(id: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
            image: editAdminUser.image === null || editAdminUser.image === undefined ? null : {
                bytes: editAdminUser.image.bytes === null || editAdminUser.image.bytes === undefined ? null : editAdminUser.image.bytes.toString("base64"),
                image: editAdminUser.image.image === null || editAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: editAdminUser.image.image.thumb.width || 0,
                        height: editAdminUser.image.image.thumb.height || 0,
                        url: editAdminUser.image.image.thumb.url,
                    },
                    width: editAdminUser.image.image.width || 0,
                    height: editAdminUser.image.image.height || 0,
                    url: editAdminUser.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function getEvent(id: string, progress?: (progress: number) => void): Promise<Event> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getEvent", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
        videoUrls: ret.videoUrls.map((e: any) => e),
    };
}

export async function getAllEvents(page: number, progress?: (progress: number) => void): Promise<Event[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "getAllEvents", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        images: e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(e.createdAt + "Z"),
        title: e.title,
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        videoUrls: e.videoUrls.map((e: any) => e),
    }));
}

export async function createEvent(newEvent: NewEvent, progress?: (progress: number) => void): Promise<Event> {
    const args = {
        newEvent: {
            images: newEvent.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            title: newEvent.title,
            date: typeof(newEvent.date) === "string" ? new Date(new Date(newEvent.date).getTime() - new Date(newEvent.date).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newEvent.date.getTime() - newEvent.date.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            videoUrls: newEvent.videoUrls.map(e => e),
        },
    };
    const ret = await makeRequest({name: "createEvent", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
        videoUrls: ret.videoUrls.map((e: any) => e),
    };
}

export async function editEvent(id: string, editEvent: EditEvent, progress?: (progress: number) => void): Promise<Event> {
    const args = {
        id: id,
        editEvent: {
            images: editEvent.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            title: editEvent.title,
            date: typeof(editEvent.date) === "string" ? new Date(new Date(editEvent.date).getTime() - new Date(editEvent.date).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editEvent.date.getTime() - editEvent.date.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            videoUrls: editEvent.videoUrls.map(e => e),
        },
    };
    const ret = await makeRequest({name: "editEvent", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
        videoUrls: ret.videoUrls.map((e: any) => e),
    };
}

export async function deleteEvent(id: string, progress?: (progress: number) => void): Promise<Event> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteEvent", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
        videoUrls: ret.videoUrls.map((e: any) => e),
    };
}

export async function getNewsById(id: string, progress?: (progress: number) => void): Promise<News> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getNewsById", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        text: ret.text,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
    };
}

export async function getAllNews(page: number, progress?: (progress: number) => void): Promise<News[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "getAllNews", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        createdAt: new Date(e.createdAt + "Z"),
        title: e.title,
        text: e.text,
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
    }));
}

export async function createNews(createNews: CreateNews, progress?: (progress: number) => void): Promise<News> {
    const args = {
        createNews: {
            image: createNews.image === null || createNews.image === undefined ? null : {
                bytes: createNews.image.bytes === null || createNews.image.bytes === undefined ? null : createNews.image.bytes.toString("base64"),
                image: createNews.image.image === null || createNews.image.image === undefined ? null : {
                    thumb: {
                        width: createNews.image.image.thumb.width || 0,
                        height: createNews.image.image.thumb.height || 0,
                        url: createNews.image.image.thumb.url,
                    },
                    width: createNews.image.image.width || 0,
                    height: createNews.image.image.height || 0,
                    url: createNews.image.image.url,
                },
            },
            title: createNews.title,
            text: createNews.text,
            date: typeof(createNews.date) === "string" ? new Date(new Date(createNews.date).getTime() - new Date(createNews.date).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(createNews.date.getTime() - createNews.date.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "createNews", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        text: ret.text,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
    };
}

export async function editNews(id: string, editNews: EditNews, progress?: (progress: number) => void): Promise<News> {
    const args = {
        id: id,
        editNews: {
            image: editNews.image === null || editNews.image === undefined ? null : {
                bytes: editNews.image.bytes === null || editNews.image.bytes === undefined ? null : editNews.image.bytes.toString("base64"),
                image: editNews.image.image === null || editNews.image.image === undefined ? null : {
                    thumb: {
                        width: editNews.image.image.thumb.width || 0,
                        height: editNews.image.image.thumb.height || 0,
                        url: editNews.image.image.thumb.url,
                    },
                    width: editNews.image.image.width || 0,
                    height: editNews.image.image.height || 0,
                    url: editNews.image.image.url,
                },
            },
            title: editNews.title,
            text: editNews.text,
            date: typeof(editNews.date) === "string" ? new Date(new Date(editNews.date).getTime() - new Date(editNews.date).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editNews.date.getTime() - editNews.date.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "editNews", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        text: ret.text,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
    };
}

export async function deleteNews(id: string, progress?: (progress: number) => void): Promise<News> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteNews", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        text: ret.text,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
    };
}

export async function getProduct(id: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getProduct", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        sizes: ret.sizes.map((e: any) => e),
        isAvailable: !!ret.isAvailable,
    };
}

export async function getAllProducts(page: number, progress?: (progress: number) => void): Promise<Product[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "getAllProducts", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        images: e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(e.createdAt + "Z"),
        title: e.title,
        description: e.description,
        price: e.price || 0,
        sizes: e.sizes.map((e: any) => e),
        isAvailable: !!e.isAvailable,
    }));
}

export async function createProduct(newProduct: NewProduct, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        newProduct: {
            images: newProduct.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            title: newProduct.title,
            description: newProduct.description,
            price: newProduct.price || 0,
            sizes: newProduct.sizes.map(e => e),
            isAvailable: !!newProduct.isAvailable,
        },
    };
    const ret = await makeRequest({name: "createProduct", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        sizes: ret.sizes.map((e: any) => e),
        isAvailable: !!ret.isAvailable,
    };
}

export async function editProduct(id: string, editProduct: EditProduct, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
        editProduct: {
            images: editProduct.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            title: editProduct.title,
            description: editProduct.description,
            price: editProduct.price || 0,
            sizes: editProduct.sizes.map(e => e),
            isAvailable: !!editProduct.isAvailable,
        },
    };
    const ret = await makeRequest({name: "editProduct", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        sizes: ret.sizes.map((e: any) => e),
        isAvailable: !!ret.isAvailable,
    };
}

export async function deleteProduct(id: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteProduct", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        sizes: ret.sizes.map((e: any) => e),
        isAvailable: !!ret.isAvailable,
    };
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function getUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        email: ret.email,
        name: ret.name,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
    };
}

export async function getAllUsers(page: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "getAllUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        createdAt: new Date(e.createdAt + "Z"),
        email: e.email,
        name: e.name,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
    }));
}

export async function createUser(newUser: NewUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUser: {
            image: newUser.image === null || newUser.image === undefined ? null : {
                bytes: newUser.image.bytes === null || newUser.image.bytes === undefined ? null : newUser.image.bytes.toString("base64"),
                image: newUser.image.image === null || newUser.image.image === undefined ? null : {
                    thumb: {
                        width: newUser.image.image.thumb.width || 0,
                        height: newUser.image.image.thumb.height || 0,
                        url: newUser.image.image.thumb.url,
                    },
                    width: newUser.image.image.width || 0,
                    height: newUser.image.image.height || 0,
                    url: newUser.image.image.url,
                },
            },
            password: newUser.password,
            email: newUser.email,
            name: newUser.name,
            phone: newUser.phone === null || newUser.phone === undefined ? null : newUser.phone,
            cpf: newUser.cpf === null || newUser.cpf === undefined ? null : newUser.cpf,
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        email: ret.email,
        name: ret.name,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
    };
}

export async function editUser(id: string, editUser: EditUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        editUser: {
            image: editUser.image === null || editUser.image === undefined ? null : {
                bytes: editUser.image.bytes === null || editUser.image.bytes === undefined ? null : editUser.image.bytes.toString("base64"),
                image: editUser.image.image === null || editUser.image.image === undefined ? null : {
                    thumb: {
                        width: editUser.image.image.thumb.width || 0,
                        height: editUser.image.image.thumb.height || 0,
                        url: editUser.image.image.thumb.url,
                    },
                    width: editUser.image.image.width || 0,
                    height: editUser.image.image.height || 0,
                    url: editUser.image.image.url,
                },
            },
            email: editUser.email,
            name: editUser.name,
            phone: editUser.phone === null || editUser.phone === undefined ? null : editUser.phone,
            cpf: editUser.cpf === null || editUser.cpf === undefined ? null : editUser.cpf,
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        email: ret.email,
        name: ret.name,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
    };
}

export async function deleteUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        email: ret.email,
        name: ret.name,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
    };
}

export async function getReservation(id: string, progress?: (progress: number) => void): Promise<Reservation> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getReservation", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            createdAt: new Date(ret.user.createdAt + "Z"),
            email: ret.user.email,
            name: ret.user.name,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            cpf: ret.user.cpf === null || ret.user.cpf === undefined ? null : ret.user.cpf,
        },
        product: {
            id: ret.product.id,
            images: ret.product.images.map((e: any) => ({
                thumb: {
                    width: e.thumb.width || 0,
                    height: e.thumb.height || 0,
                    url: e.thumb.url,
                },
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            createdAt: new Date(ret.product.createdAt + "Z"),
            title: ret.product.title,
            description: ret.product.description,
            price: ret.product.price || 0,
            sizes: ret.product.sizes.map((e: any) => e),
            isAvailable: !!ret.product.isAvailable,
        },
        status: ret.status,
        size: ret.size,
        removedAt: ret.removedAt === null || ret.removedAt === undefined ? null : new Date(parseInt(ret.removedAt.split("-")[0], 10), parseInt(ret.removedAt.split("-")[1], 10) - 1, parseInt(ret.removedAt.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllReservations(page: number, progress?: (progress: number) => void): Promise<Reservation[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "getAllReservations", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            createdAt: new Date(e.user.createdAt + "Z"),
            email: e.user.email,
            name: e.user.name,
            phone: e.user.phone === null || e.user.phone === undefined ? null : e.user.phone,
            cpf: e.user.cpf === null || e.user.cpf === undefined ? null : e.user.cpf,
        },
        product: {
            id: e.product.id,
            images: e.product.images.map((e: any) => ({
                thumb: {
                    width: e.thumb.width || 0,
                    height: e.thumb.height || 0,
                    url: e.thumb.url,
                },
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            createdAt: new Date(e.product.createdAt + "Z"),
            title: e.product.title,
            description: e.product.description,
            price: e.product.price || 0,
            sizes: e.product.sizes.map((e: any) => e),
            isAvailable: !!e.product.isAvailable,
        },
        status: e.status,
        size: e.size,
        removedAt: e.removedAt === null || e.removedAt === undefined ? null : new Date(parseInt(e.removedAt.split("-")[0], 10), parseInt(e.removedAt.split("-")[1], 10) - 1, parseInt(e.removedAt.split("-")[2], 10)),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function removeProductReservation(reservationId: string, progress?: (progress: number) => void): Promise<Reservation> {
    const args = {
        reservationId: reservationId,
    };
    const ret = await makeRequest({name: "removeProductReservation", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            createdAt: new Date(ret.user.createdAt + "Z"),
            email: ret.user.email,
            name: ret.user.name,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            cpf: ret.user.cpf === null || ret.user.cpf === undefined ? null : ret.user.cpf,
        },
        product: {
            id: ret.product.id,
            images: ret.product.images.map((e: any) => ({
                thumb: {
                    width: e.thumb.width || 0,
                    height: e.thumb.height || 0,
                    url: e.thumb.url,
                },
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            createdAt: new Date(ret.product.createdAt + "Z"),
            title: ret.product.title,
            description: ret.product.description,
            price: ret.product.price || 0,
            sizes: ret.product.sizes.map((e: any) => e),
            isAvailable: !!ret.product.isAvailable,
        },
        status: ret.status,
        size: ret.size,
        removedAt: ret.removedAt === null || ret.removedAt === undefined ? null : new Date(parseInt(ret.removedAt.split("-")[0], 10), parseInt(ret.removedAt.split("-")[1], 10) - 1, parseInt(ret.removedAt.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getTournament(id: string, progress?: (progress: number) => void): Promise<Tournament> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getTournament", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        gamesImage: ret.gamesImage === null || ret.gamesImage === undefined ? null : {
            thumb: {
                width: ret.gamesImage.thumb.width || 0,
                height: ret.gamesImage.thumb.height || 0,
                url: ret.gamesImage.thumb.url,
            },
            width: ret.gamesImage.width || 0,
            height: ret.gamesImage.height || 0,
            url: ret.gamesImage.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
        lineup: ret.lineup,
        type: ret.type,
        topScorer: ret.topScorer === null || ret.topScorer === undefined ? null : ret.topScorer,
        bestDefense: ret.bestDefense === null || ret.bestDefense === undefined ? null : ret.bestDefense,
        ranking: ret.ranking.map((e: any) => e),
    };
}

export async function getAllTournaments(page: number, progress?: (progress: number) => void): Promise<Tournament[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "getAllTournaments", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        gamesImage: e.gamesImage === null || e.gamesImage === undefined ? null : {
            thumb: {
                width: e.gamesImage.thumb.width || 0,
                height: e.gamesImage.thumb.height || 0,
                url: e.gamesImage.thumb.url,
            },
            width: e.gamesImage.width || 0,
            height: e.gamesImage.height || 0,
            url: e.gamesImage.url,
        },
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        lineup: e.lineup,
        type: e.type,
        topScorer: e.topScorer === null || e.topScorer === undefined ? null : e.topScorer,
        bestDefense: e.bestDefense === null || e.bestDefense === undefined ? null : e.bestDefense,
        ranking: e.ranking.map((e: any) => e),
    }));
}

export async function createTournament(newTournament: NewTournament, progress?: (progress: number) => void): Promise<Tournament> {
    const args = {
        newTournament: {
            image: newTournament.image === null || newTournament.image === undefined ? null : {
                bytes: newTournament.image.bytes === null || newTournament.image.bytes === undefined ? null : newTournament.image.bytes.toString("base64"),
                image: newTournament.image.image === null || newTournament.image.image === undefined ? null : {
                    thumb: {
                        width: newTournament.image.image.thumb.width || 0,
                        height: newTournament.image.image.thumb.height || 0,
                        url: newTournament.image.image.thumb.url,
                    },
                    width: newTournament.image.image.width || 0,
                    height: newTournament.image.image.height || 0,
                    url: newTournament.image.image.url,
                },
            },
            gamesImage: newTournament.gamesImage === null || newTournament.gamesImage === undefined ? null : {
                bytes: newTournament.gamesImage.bytes === null || newTournament.gamesImage.bytes === undefined ? null : newTournament.gamesImage.bytes.toString("base64"),
                image: newTournament.gamesImage.image === null || newTournament.gamesImage.image === undefined ? null : {
                    thumb: {
                        width: newTournament.gamesImage.image.thumb.width || 0,
                        height: newTournament.gamesImage.image.thumb.height || 0,
                        url: newTournament.gamesImage.image.thumb.url,
                    },
                    width: newTournament.gamesImage.image.width || 0,
                    height: newTournament.gamesImage.image.height || 0,
                    url: newTournament.gamesImage.image.url,
                },
            },
            name: newTournament.name,
            date: typeof(newTournament.date) === "string" ? new Date(new Date(newTournament.date).getTime() - new Date(newTournament.date).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newTournament.date.getTime() - newTournament.date.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            lineup: newTournament.lineup,
            type: newTournament.type,
            topScorer: newTournament.topScorer === null || newTournament.topScorer === undefined ? null : newTournament.topScorer,
            bestDefense: newTournament.bestDefense === null || newTournament.bestDefense === undefined ? null : newTournament.bestDefense,
            ranking: newTournament.ranking.map(e => e),
        },
    };
    const ret = await makeRequest({name: "createTournament", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        gamesImage: ret.gamesImage === null || ret.gamesImage === undefined ? null : {
            thumb: {
                width: ret.gamesImage.thumb.width || 0,
                height: ret.gamesImage.thumb.height || 0,
                url: ret.gamesImage.thumb.url,
            },
            width: ret.gamesImage.width || 0,
            height: ret.gamesImage.height || 0,
            url: ret.gamesImage.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
        lineup: ret.lineup,
        type: ret.type,
        topScorer: ret.topScorer === null || ret.topScorer === undefined ? null : ret.topScorer,
        bestDefense: ret.bestDefense === null || ret.bestDefense === undefined ? null : ret.bestDefense,
        ranking: ret.ranking.map((e: any) => e),
    };
}

export async function editTournament(id: string, editTournament: EditTournament, progress?: (progress: number) => void): Promise<Tournament> {
    const args = {
        id: id,
        editTournament: {
            image: editTournament.image === null || editTournament.image === undefined ? null : {
                bytes: editTournament.image.bytes === null || editTournament.image.bytes === undefined ? null : editTournament.image.bytes.toString("base64"),
                image: editTournament.image.image === null || editTournament.image.image === undefined ? null : {
                    thumb: {
                        width: editTournament.image.image.thumb.width || 0,
                        height: editTournament.image.image.thumb.height || 0,
                        url: editTournament.image.image.thumb.url,
                    },
                    width: editTournament.image.image.width || 0,
                    height: editTournament.image.image.height || 0,
                    url: editTournament.image.image.url,
                },
            },
            gamesImage: editTournament.gamesImage === null || editTournament.gamesImage === undefined ? null : {
                bytes: editTournament.gamesImage.bytes === null || editTournament.gamesImage.bytes === undefined ? null : editTournament.gamesImage.bytes.toString("base64"),
                image: editTournament.gamesImage.image === null || editTournament.gamesImage.image === undefined ? null : {
                    thumb: {
                        width: editTournament.gamesImage.image.thumb.width || 0,
                        height: editTournament.gamesImage.image.thumb.height || 0,
                        url: editTournament.gamesImage.image.thumb.url,
                    },
                    width: editTournament.gamesImage.image.width || 0,
                    height: editTournament.gamesImage.image.height || 0,
                    url: editTournament.gamesImage.image.url,
                },
            },
            name: editTournament.name,
            date: typeof(editTournament.date) === "string" ? new Date(new Date(editTournament.date).getTime() - new Date(editTournament.date).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editTournament.date.getTime() - editTournament.date.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            lineup: editTournament.lineup,
            type: editTournament.type,
            topScorer: editTournament.topScorer === null || editTournament.topScorer === undefined ? null : editTournament.topScorer,
            bestDefense: editTournament.bestDefense === null || editTournament.bestDefense === undefined ? null : editTournament.bestDefense,
            ranking: editTournament.ranking.map(e => e),
        },
    };
    const ret = await makeRequest({name: "editTournament", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        gamesImage: ret.gamesImage === null || ret.gamesImage === undefined ? null : {
            thumb: {
                width: ret.gamesImage.thumb.width || 0,
                height: ret.gamesImage.thumb.height || 0,
                url: ret.gamesImage.thumb.url,
            },
            width: ret.gamesImage.width || 0,
            height: ret.gamesImage.height || 0,
            url: ret.gamesImage.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
        lineup: ret.lineup,
        type: ret.type,
        topScorer: ret.topScorer === null || ret.topScorer === undefined ? null : ret.topScorer,
        bestDefense: ret.bestDefense === null || ret.bestDefense === undefined ? null : ret.bestDefense,
        ranking: ret.ranking.map((e: any) => e),
    };
}

export async function deleteTournament(id: string, progress?: (progress: number) => void): Promise<Tournament> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteTournament", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        gamesImage: ret.gamesImage === null || ret.gamesImage === undefined ? null : {
            thumb: {
                width: ret.gamesImage.thumb.width || 0,
                height: ret.gamesImage.thumb.height || 0,
                url: ret.gamesImage.thumb.url,
            },
            width: ret.gamesImage.width || 0,
            height: ret.gamesImage.height || 0,
            url: ret.gamesImage.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
        lineup: ret.lineup,
        type: ret.type,
        topScorer: ret.topScorer === null || ret.topScorer === undefined ? null : ret.topScorer,
        bestDefense: ret.bestDefense === null || ret.bestDefense === undefined ? null : ret.bestDefense,
        ranking: ret.ranking.map((e: any) => e),
    };
}

export async function getWorkout(id: string, progress?: (progress: number) => void): Promise<Workout> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getWorkout", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        description: ret.description,
        url: ret.url,
    };
}

export async function getAllWorkouts(page: number, progress?: (progress: number) => void): Promise<Workout[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "getAllWorkouts", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        title: e.title,
        description: e.description,
        url: e.url,
    }));
}

export async function createWorkout(newWorkout: NewWorkout, progress?: (progress: number) => void): Promise<Workout> {
    const args = {
        newWorkout: {
            title: newWorkout.title,
            description: newWorkout.description,
            url: newWorkout.url,
        },
    };
    const ret = await makeRequest({name: "createWorkout", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        description: ret.description,
        url: ret.url,
    };
}

export async function editWorkout(id: string, editWorkout: EditWorkout, progress?: (progress: number) => void): Promise<Workout> {
    const args = {
        id: id,
        editWorkout: {
            title: editWorkout.title,
            description: editWorkout.description,
            url: editWorkout.url,
        },
    };
    const ret = await makeRequest({name: "editWorkout", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        description: ret.description,
        url: ret.url,
    };
}

export async function deleteWorkout(id: string, progress?: (progress: number) => void): Promise<Workout> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteWorkout", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        title: ret.title,
        description: ret.description,
        url: ret.url,
    };
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
