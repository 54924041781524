import { makeAutoObservable } from "mobx";
import { AttributeShelf, FormShelf, LoaderShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import ImagePickerShelf from "~/shelves/ImagePicker";
import strings from "~/resources/strings";

const pageStrings = strings.news.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		title: "",
		text: "",
	});
	public urlFormShelf = new FormShelf({
		url: "",
	});

	public loader = new LoaderShelf();
	public imageShelf = new ImagePickerShelf();

	public date = new AttributeShelf(new Date());
	public videosUrl = new AttributeShelf<string[]>([]);

	public id?: string;

	constructor(id?: string) {

		if (id) {
			this.id = id;
			this.getNews(id);
		}
		makeAutoObservable(this);
	}

	public getNews = async (id: string) => {
		this.loader.tryStart();
		try {
			const news = await api.getNewsById(id);
			this.setInitValues(news);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (news: api.News) => {
		this.formShelf = new FormShelf({
			title: news.title,
			text: news.text,
		});
		this.date.setValue(news.date);
		if (news.image){
			this.imageShelf.getPickerFields().setUploadedImage(news.image);
		}
	};


	public createOrEditNews= async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.formShelf.getValues();
			const { title, text } = data;

			if (this.id) {

				await api.editNews(this.id, {
					image: this.imageShelf.uncertainfiedImage,
					title,
					text,
					date: this.date.value,
				});

				showSuccessToast(pageStrings.edit.success);
			} else {
				await api.createNews({
					image: this.imageShelf.uncertainfiedImage,
					title,
					text,
					date: this.date.value,
				});

				showSuccessToast(pageStrings.create.success);
			}

			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
