import { makeAutoObservable } from "mobx";
import { AttributeShelf, FormShelf, LoaderShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import ImagePickerShelf from "~/shelves/ImagePicker";
import strings from "~/resources/strings";

const pageStrings = strings.products.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		title: "",
		description: "",
	});
	public sizeFormShelf = new FormShelf({
		size: "",
	});

	public loader = new LoaderShelf();
	public firstImage = new ImagePickerShelf();
	public secondImage = new ImagePickerShelf();
	public thirdImage = new ImagePickerShelf();

	public price = new AttributeShelf("");
	public sizes = new AttributeShelf<string[]>([]);
	public isAvaliable = new AttributeShelf(false);

	public id?: string;

	constructor(id?: string) {

		if (id) {
			this.id = id;
			this.getProduct(id);
		}
		makeAutoObservable(this);
	}

	public getProduct = async (id: string) => {
		this.loader.tryStart();
		try {
			const product = await api.getProduct(id);
			this.setInitValues(product);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (product: api.Product) => {
		this.formShelf = new FormShelf({
			title: product.title,
			description: product.description,
		});

		this.sizes.setValue(product.sizes);
		this.price.setValue(product.price.toString());
		this.isAvaliable.setValue(product.isAvailable);

		if (product.images.length > 0){
			this.firstImage.getPickerFields().setUploadedImage(product.images[0]);
		}
		if (product.images.length > 1){
			this.secondImage.getPickerFields().setUploadedImage(product.images[1]);
		}
		if (product.images.length > 2){
			this.thirdImage.getPickerFields().setUploadedImage(product.images[2]);
		}
	};

	public get imagesToPicker() {
		return [
			this.firstImage.getPickerFields(),
			this.secondImage.getPickerFields(),
			this.thirdImage.getPickerFields(),
		];
	}

	public get uploadedImages() {
		const images: api.UncertainImage[] = [];
		const imagePickers = [
			this.firstImage,
			this.secondImage,
			this.thirdImage,
		];
		imagePickers.forEach((imagePicker) => {
			if (imagePicker.uncertainfiedImage) {
				images.push(imagePicker.uncertainfiedImage);
			}
		});
		return images;
	}

	public deleteImage = (imageIndex: number) => {
		if (imageIndex === 0){
			this.firstImage = new ImagePickerShelf();
		}
		if (imageIndex === 1){
			this.secondImage = new ImagePickerShelf();
		}
		if (imageIndex === 2){
			this.thirdImage = new ImagePickerShelf();
		}
	}

	public pushSize = () => {
		this.sizes.value.push(this.sizeFormShelf.getValue("size"));
		this.clear();
	}

	public clear = () => {
		this.sizeFormShelf = new FormShelf({
			size: "",
		});
	}

	public deleteUrl = (urlIndex: number) => {
		this.sizes.value = this.sizes.value.filter((value, index) => (index !== urlIndex));
	}

	public createOrEditProduct= async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.formShelf.getValues();
			const { title, description } = data;

			if (this.id) {

				await api.editProduct(this.id, {
					images: this.uploadedImages,
					title,
					description,
					sizes: this.sizes.value,
					price: parseInt(this.price.value),
					isAvailable: this.isAvaliable.value,
				});

				showSuccessToast(pageStrings.edit.success);
			} else {
				await api.createProduct({
					images: this.uploadedImages,
					title,
					description,
					sizes: this.sizes.value,
					price: parseInt(this.price.value),
					isAvailable: this.isAvaliable.value,
				});

				showSuccessToast(pageStrings.create.success);
			}

			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
