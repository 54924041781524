import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Stack,
	Button,
	Center,
	HStack,
	useDisclosure,
	Select,
	Box,
	Textarea,
} from "@chakra-ui/react";
import {
	DatePicker,
	ImagePicker,
	Section,
	TextInput,
	Modal,
	RankingList,
	Label,
} from "~/components";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";
import Store from "./store";
import api from "~/resources/api";

interface IParams {
	id?: string;
}

const CreateOrEdit: React.FC = () => {
	const pageStrings = strings.tournaments.createOrEdit;
	const commonStrings = strings.common;

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { id } = useParams<IParams>();
	const history = useHistory();

	const onSuccess = () => {
		history.goBack();
	};

	const onCloseAndClear = () => {
		store.clear();
		onClose();
	};

	const store = useLocalObservable(() => new Store(id));

	const onSubmit = () => {
		store.createOrEditTournament(onSuccess);
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			p={{ base: 0, sm: 16 }}
		>
			<Section title={id ? pageStrings.edit.title : pageStrings.create.title}>
				<Stack w="100%" spacing={spacing.s8}>
					<Center>
						<ImagePicker
							pickImage={store.imageShelf.getPickerFields().pick}
							src={store.imageShelf.src}
							loading={store.imageShelf.loader.isLoading}
						/>
					</Center>
					<TextInput
						labelText={commonStrings.fields.name}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("name")}
						{...store.formShelf.field("name")}
					/>
					<DatePicker
						label={commonStrings.fields.date}
						selectedDate={store.date.value}
						onChangeDate={store.date.setValue}
						isDisabled={store.loader.isLoading}
					/>
					<Box>
						<Label mb={spacing.s2}>{pageStrings.fields.type}</Label>
						<Select
							variant="filled"
							onChange={(e) => (
								store.type.setValue(
									api.valueFromTranslationTournamentType(e.currentTarget.value),
								)
							)}
							value={api.translateTournamentType(store.type.value)}
						>
							{
								api.allTranslatedValuesTournamentType().map((value, index) => (
									<option key={`${value}${index}`} value={value}>
										{value}
									</option>
								))
							}
						</Select>
					</Box>
					<Box>
						<Label mb={spacing.s2}>{pageStrings.fields.gamesTable}</Label>
						<ImagePicker
							pickImage={store.gamesImageShelf.getPickerFields().pick}
							src={store.gamesImageShelf.src}
							loading={store.gamesImageShelf.loader.isLoading}
						/>
					</Box>
					<RankingList
						isLoading={store.loader.isLoading}
						isEdit={!!store.currentPlayerEditing.value}
						setCurrentPlayerEditing={store.currentPlayerEditing.setValue}
						players={store.ranking.value}
						onAdd={onOpen}
						onEdit={(playerIndex) => {
							store.currentPlayerEditing.setValue(playerIndex);
							store.setEditingPlayer();
							onOpen();
						}}
						onDelete={store.deletePlayer}
					/>
					<Modal
						isOpen={isOpen}
						onClose={onCloseAndClear}
						title={store.currentPlayerEditing.value !== null ? pageStrings.edit.modal.title : pageStrings.create.modal.title}
					>
						<Flex flexDir="column" alignItems="center">
							<TextInput
								type="text"
								isDisabled={store.loader.isLoading}
								errorText={store.playerFormShelf.fieldError.getFieldError("pĺayer")}
								{...store.playerFormShelf.field("pĺayer")}
							/>
							<HStack mt={spacing.s5}>
								<Button
									isLoading={store.loader.isLoading}
									onClick={() => {
										if (store.currentPlayerEditing.value !== null) {
											store.editPlayer();
										} else {
											store.pushPlayer();
										}
										onCloseAndClear();
									}}
									colorScheme="green"
								>
									{store.currentPlayerEditing.value !== null ? strings.actions.save : strings.actions.add}
								</Button>
								<Button
									isLoading={store.loader.isLoading}
									onClick={() => {
										onCloseAndClear();
									}}
									bgColor="gray.400"
								>
									{strings.actions.back}
								</Button>
							</HStack>
						</Flex>
					</Modal>

					<TextInput
						labelText={pageStrings.fields.topScorer}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("topScorer")}
						{...store.formShelf.field("topScorer")}
					/>
					<TextInput
						labelText={pageStrings.fields.bestDefense}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("bestDefense")}
						{...store.formShelf.field("bestDefense")}
					/>
					<Box>
						<Label mb={spacing.s2}>{pageStrings.fields.lineup}</Label>
						<Textarea
							variant="filled"
							type="text"
							minH="2xs"
							isDisabled={store.loader.isLoading}
							{...store.formShelf.field("lineup")}
						/>
					</Box>
					<Button
						isLoading={store.loader.isLoading}
						onClick={onSubmit}
					>
						{pageStrings.confirmButton(!!id)}
					</Button>
				</Stack>
			</Section>
		</Flex>
	);
};

export default observer(CreateOrEdit);
