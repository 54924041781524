import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Text, Tr } from "@chakra-ui/react";
import moment from "moment";
import { Table, TableCellWithActionButtons } from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "react-router-dom";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import Store from "./store";

const TableView: React.FC = () => {
	const pageStrings = strings.reservations.table;
	const store = useLocalObservable(() => new Store());
	const { dialog } = useGlobalStore();

	const history = useHistory();

	const onGoToReservationDetails = (id: string) =>
		history.push(`reservations/details/${id}`);

	const onRemoveReservation = async (reservationId: string) => {
		await store.removeReservation(reservationId);
		dialog.closeDialog();
	};

	const openRemoveReservationDialog = (reservationId: string) => {
		dialog.showDialog({
			title: strings.reservations.removeReservation.dialog.title,
			closeOnOverlayClick: true,
			buttons: [
				{
					title: strings.reservations.removeReservation.dialog.remove,
					onPress: () => onRemoveReservation(reservationId),
					buttonProps: { bg: "red.500" },
				},
				{
					title: strings.reservations.removeReservation.dialog.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex p={{ base: "2", lg: "16" }}>
			<Table
				data={store._items}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				renderRow={(item) => (
					<Tr key={item.id}>
						<Td>
							<Text>
								{moment(item.createdAt).format(
									strings.moment.date,
								)}
							</Text>
						</Td>
						<Td>
							<Text>{item.product.title}</Text>
						</Td>
						<Td>
							<Text>{item.size}</Text>
						</Td>
						<Td>
							<Text>{item.user.email}</Text>
						</Td>
						<Td>
							<Text>
								{item.removedAt
									? moment(item.removedAt).format(strings.moment.date)
									: "-"}
							</Text>
						</Td>
						<TableCellWithActionButtons
							onView={() => onGoToReservationDetails(item.id)}
							onDelete={item.removedAt ? undefined : () => openRemoveReservationDialog(item.id)}
						/>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				hasNextPage={store._isHaveNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
