import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Text, Tr } from "@chakra-ui/react";
import { Table, TableCellWithActionButtons } from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "react-router-dom";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import API from "~/resources/api";
import Store from "./store";
import moment from "moment";

const TableView: React.FC = () => {
	const pageStrings = strings.news.table;
	const modal = strings.common.modal;
	const store = useLocalObservable(() => new Store());
	const { dialog } = useGlobalStore();

	const history = useHistory();

	const onDeleteNews = (id: string) => {
		store.deleteNews(id);
		dialog.closeDialog();
	};

	const onGoToEditNews = (id: string) => history.push(`news/edit/${id}`);

	const onGoToCreateNews = () => history.push("news/create/");

	const onGoToDetails = (id: string) => history.push(`news/details/${id}`);

	const openDialog = (event: API.News) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(event.title),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteNews(event.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex p={{ base: "2", lg: "16"}}>
			<Table
				data={store._items}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				onAdd={onGoToCreateNews}
				renderRow={(item) => (
					<Tr key={item.id} >
						<Td>
							<Text>{item.title}</Text>
						</Td>
						<Td>
							<Text>{moment(item.date).format("l")}</Text>
						</Td>
						<TableCellWithActionButtons
							onEdit={() => onGoToEditNews(item.id)}
							onDelete={() => openDialog(item)}
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				hasNextPage={store._isHaveNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
