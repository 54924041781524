import React from "react";
import {
	Input,
	InputGroup,
	InputRightElement,
	Box,
	BoxProps,
	IconButton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import spacing from "../../themes/spacing";

const { s2 } = spacing;

interface IProps {
	name: string;
	value: string;
	onChange: (value: string) => void;
	onClickSearch: () => void;
	placeholder?: string;
	containerProps?: BoxProps;
}

export const SearchInput: React.FC<IProps> = (props) => {
	const {
		name,
		value,
		onChange,
		onClickSearch,
		placeholder,
		containerProps,
	} = props;

	return (
		<Box h={58} {...containerProps}>
			<InputGroup h="100%" w="100%">
				<Input
					name={name}
					value={value}
					h="100%"
					placeholder={placeholder}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						onChange(e.currentTarget.value);
					}}
					focusBorderColor="primary.700"
				/>
				<InputRightElement h="100%">
					<IconButton
						h="100%"
						aria-label={placeholder || ""}
						icon={<SearchIcon />}
						onClick={onClickSearch}
						color="primary.700"
						bgColor="transparent"
						mr={s2}
					/>
				</InputRightElement>
			</InputGroup>
		</Box>
	);
};
