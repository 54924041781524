export const colors = {
	primary: {
		50: "#ECEFF8",
		100: "#CBD1EC",
		200: "#A9B4E0",
		300: "#8797D4",
		400: "#6579C8",
		500: "#445CBB",
		600: "#364A96",
		700: "#293770",
		800: "#1B254B",
		900: "#0E1225",
	},
	secondary: {
		50: "#FFFAE5",
		100: "#FFF0B8",
		200: "#FFE68A",
		300: "#FFDD5C",
		400: "#FFD32E",
		500: "#FFCA00",
		600: "#CCA100",
		700: "#997900",
		800: "#665100",
		900: "#332800",
	},
};
