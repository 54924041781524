import "moment/locale/pt-br";
import LocalizedStrings from "localized-strings";
import format from "./format";
import moment from "moment";

// MARK: Implementation
const strings = new LocalizedStrings({
	ptBR: {
		adminUser: {
			success: "Editado com sucesso",
		},
		components: {
			imagePicker: {
				selectAnImage: "Selecione uma imagem",
			},
			rankingList: {
				title: "Classificação",
				place: (place: number)=> `${place}º`,
				addPlayer: "Adicionar jogador",
			},
		},
		nav: {
			home: "Home",
			users: "Usuários",
			tournaments: "Competições",
			events: "Memoriais",
			workouts: "Treinos",
			news: "Notícias",
			products: "Produtos",
			reservations: "Reservas",
			logout: "Sair",
			close: "Fechar",
		},
		error: {
			stillLoading: "Ainda Carregando, aguarde.",
			notLoggedIn: "Usuario não logado",
			default: "Houve um erro, tente novamente mais tarde.",
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
			select: "Selecionar",
			back: "Voltar",
			add: "Adicionar",
			exportCsv: "Exportar CSV",
			purchase: "Comprar",
			confirm: "Confirmar",
		},
		common: {
			noResutls: "Sem Resultados",
			delete: "Clique para deletar",
			details: "Clique para ver mais informações",
			edit: "Clique para editar",
			cancel: "Cancelar",
			confirm: (isEdit?: boolean) => isEdit ? "Salvar" : "Adicionar",
			modal: {
				title: "Deletar",
				description: (itemName: string) => `Tem certeza que deseja deletar ${itemName}?`,
				button: {
					delete: "Deletar",
					cancel: "Cancelar",
				},
			},
			fields: {
				title: "Título",
				name: "Nome",
				email: "E-mail",
				phone: "Celular",
				password: "Senha",
				cpf: "CPF",
				date: "Data",
				text: "Texto",
				price: "Preço",
				description: "Descrição",
			},
		},
		home: {
			welcome: "Bem vindo!",
		},
		login: {
			loginIn: "Login",
			recoveryPassword: "Recuperar Senha",
			success: (userName: string) => `Bem vindo ${userName}.`,
			fields: {
				email: "E-mail",
				password: "Senha",
			},
		},
		users: {
			table: {
				title: "Lista de Usuários",
				header: ["Usuário", "E-mail", "Phone"],
				delete: (userName: string) => `Usuário ${userName} foi deletado!`,
			},
			createOrEdit: {
				create: {
					title: "Criar Usuário",
					success: "Usuário criado com sucesso",
				},
				edit: {
					title: "Editar Usuário",
					success: "Usuário editado com sucesso",
				},
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Criar",
				backButton: "Voltar",
			},
			details: {
				title: "Detalhes",
			},

		},
		tournaments: {
			table: {
				title: "Lista de Competições",
				header: ["Nome", "Tipo", "Data"],
				delete: (tournamentName: string) => `Competição ${tournamentName} foi deletada!`,
			},
			createOrEdit: {
				create: {
					title: "Criar Competição",
					success: "Competição criada com sucesso",
					modal: {
						title: "Adicionar Jogador",
					},
				},
				edit: {
					title: "Editar Competição",
					success: "Competição editada com sucesso",
					modal: {
						title: "Editar Jogador",
					},
				},
				fields: {
					lineup: "Escalação",
					topScorer: "Artilheiro",
					bestDefense: "Melhor defesa",
					type: "Tipo",
					gamesTable: "Tabela de jogos",
				},
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Criar",
			},
		},
		workouts: {
			table: {
				title: "Lista de Treinos",
				header: ["Título", "Descrição", "Data"],
				delete: (workoutTitle: string) => `Competição ${workoutTitle} foi deletada!`,
			},
			createOrEdit: {
				create: {
					title: "Criar Treino",
					success: "Treino criado com sucesso",
					modal: {
						title: "Adicionar Jogador",
					},
				},
				edit: {
					title: "Editar Treino",
					success: "Treino editado com sucesso",
					modal: {
						title: "Editar Jogador",
					},
				},
				fields: {
					description: "Descrição",
					url: "Link do vídeo",
				},
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Criar",
			},
		},
		events: {
			table: {
				title: "Lista de Memoriais",
				header: ["Título", "Data"],
				delete: (memorialName: string) => `Memorial ${memorialName} foi deletado!`,
			},
			createOrEdit: {
				create: {
					title: "Criar Memorial",
					success: "Memorial criado com sucesso",
					modal: {
						title: "Adicionar Url",
					},
				},
				edit: {
					title: "Editar Memorial",
					success: "Memorial editado com sucesso",
				},
				listTitle: "Vídeos",
				addURL: "Adicionar URL",
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Criar",
			},
		},
		products: {
			table: {
				title: "Lista de Produtos",
				header: ["Título", "Data"],
				delete: (productTitle: string) => `Produto ${productTitle} foi deletado!`,
			},
			createOrEdit: {
				create: {
					title: "Criar Produtos",
					success: "Produto criado com sucesso",
					modal: {
						title: "Adicionar Tamanho",
					},
				},
				edit: {
					title: "Editar Produtos",
					success: "Produto editado com sucesso",
				},
				listTitle: "Tamanhos",
				available: "Disponível",
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Criar",
			},
		},
		reservations: {
			table: {
				title: "Lista de Reservas",
				header: ["Data", "Produto", "Tamanho", "Usuário", "Data de remoção"],
			},
			details: {
				sections: {
					reservation: {
						title: "Reserva",
						date: "Data",
						productName: "Nome do produto",
						size: "Tamanho",
						removalDate: "Data de remoção",
					},
					user: {
						title: "Usuário",
						name: "Nome",
						email: "E-mail",
						phone: "Telefone",
					},
					actions: {
						title: "Ações",
						seeUser: "Ver usuário",
						seeProduct: "Ver produto",
						removeReservation: "Remover reserva",
					},
				},
			},
			removeReservation: {
				success: "Solicitação de reserva removida com sucesso",
				dialog: {
					title: "Tem certeza que deseja remover a solicitação de reserva?",
					remove: "Remover",
					cancel: "Cancelar",
				},
			},
		},
		news: {
			table: {
				title: "Lista de Notícias",
				header: ["Título", "Data"],
				delete: (memorialName: string) => `Notícia ${memorialName} foi deletada!`,
			},
			createOrEdit: {
				create: {
					title: "Criar Notícia",
					success: "Notícia criada com sucesso",
					modal: {
						title: "Adicionar Url",
					},
				},
				edit: {
					title: "Editar Notícia",
					success: "Notícia editada com sucesso",
				},
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Criar",
			},
			details: {
				title: "Detalhes",
			},
		},
		imagePicker: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder:
				"Clique em adicionar para escolher uma ou mais imagens",
			addFilePlaceholder: "Clique em adicionar para escolher um arquivo",
			addFilesPlaceholder:
				"Clique em adicionar para escolher um ou mais arquivos",
			addImageBtn: "Adicionar",
			missingImage: "A imagem é obrigatória",
		},
		recoveryPage: {
			success: "Bem vindo!",
			title: "Recuperação de Senha",
			newAccess: "Novo Acesso",
			sendSuccess: "E-mail enviado com sucesso!",
			recoverPasswordButton: "Enviar e-mail",
			newPasswordButton: "Alterar Senha",
			backButton: "Voltar",
			email: "Email",
			token: "Token",
			validToken: "Token válido!",
			confirmTokenButton: "Confirmar Token",
			tokenButton: "Já possui um token?",
			noTokenButton: "Não possuo token",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
			samePasswordError: "As senhas não correspondem",
		},
		formatters: {
			date: (date: Date) => `${moment(date).format("L")}`,
			weight: (weight: number) => `${weight} kg`,
			...format,
		},
		enum: {
			TournamentType: {
				internal: "Interno",
				external: "Externo",
			},
		},
	},
});

export default strings;
