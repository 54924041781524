import React from "react";
import { observer } from "mobx-react-lite";
import {
	Box,
	Button,
	HStack,
	List,
	ListItem,
	Image,
	Text,
	Center,
	IconButton,
} from "@chakra-ui/react";
import FirstMedalIcon from "../../../static/svgs/FirstMedalIcon.svg";
import SecondMedalIcon from "../../../static/svgs/SecondMedalIcon.svg";
import ThirdMedalIcon from "../../../static/svgs/ThirdMedalIcon.svg";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { Label } from "..";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";

interface IProps{
	players: string[];
	isLoading: boolean;
	isEdit: boolean;
	setCurrentPlayerEditing: (playerIndex: number) => void;
	onAdd: () => void;
	onEdit: (playerIndex: number) => void;
	onDelete: (playerIndex: number) => void;
}

export const RankingList: React.FC<IProps> = observer((props) => {
	const {
		players,
		isLoading,
		onAdd,
		onEdit,
		onDelete,
	} = props;

	const componentStrings = strings.components.rankingList;

	const getPlaceIcon = (place: number) => {
		if (place === 1){
			return (
				<Image
					h={5}
					src={FirstMedalIcon}
				/>
			);
		}
		if (place === 2){
			return (
				<Image
					h={5}
					src={SecondMedalIcon}
				/>
			);
		}
		if (place === 3){
			return (
				<Image
					h={5}
					src={ThirdMedalIcon}
				/>
			);
		}

		return (<Box w={5} />);
	};

	return (
		<List spacing={3}>
			<ListItem>
				<Label>{componentStrings.title}</Label>
			</ListItem>
			{players.map((player,index)=>(
				<ListItem key={`${player}${index}`}>
					<HStack space={spacing.s2}>
						{getPlaceIcon(index+1)}
						<Text color="gray.500" fontSize="md" fontWeight="bold">{componentStrings.place(index+1)}</Text>
						<Text fontSize="md" color="gray.500" flexGrow={1}>{player}</Text>
						<IconButton
							variant="icon"
							colorScheme="secondary"
							aria-label="Edit"
							size="sm"
							icon={<EditIcon />}
							onClick={()=>onEdit(index)}
						/>
						<IconButton
							variant="icon"
							colorScheme="secondary"
							aria-label="Edit"
							size="sm"
							icon={<DeleteIcon />}
							onClick={()=>onDelete(index)}
						/>
					</HStack>
				</ListItem>
			))}
			<ListItem>
				<Center>
					<Button
						isLoading={isLoading}
						onClick={onAdd}
						size="sm"
						colorScheme="green"
					>
						{componentStrings.addPlayer}
					</Button>
				</Center>
			</ListItem>
		</List>
	);
});
