import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import CreateOrEdit from "./CreateOrEdit";

const Products: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/products" component={Table} />
		<Route
			path="/dashboard/products/create/"
			component={CreateOrEdit}
		/>
		<Route
			path="/dashboard/products/edit/:id"
			component={CreateOrEdit}
		/>
	</Switch>
);

export default observer(Products);
