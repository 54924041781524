import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Center, VStack, Image } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { DetailsRow, Section, DetaislContent } from "~/components";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";
import Store from "./store";

interface IParams {
	id: string;
}

const Details: React.FC = () => {
	const pageStrings = strings.users.details;
	const commonStrings = strings.common;
	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(id));

	return (
		<Center
			w="100%"
			p={{ base: 0, sm: 16 }}
		>
			<Section title={pageStrings.title}>
				<DetaislContent
					loading={store.modelShelf.loader.isLoading}
					item={store.modelShelf._model}
					renderItem={(item) => (
						<VStack spacing={spacing.s2} w="100%" display="inline">
							{item.image && (
								<Center
									justifyContent="center"
								>
									<Image
										borderRadius="md"
										maxW="200px"
										maxH="200px"
										src={item.image.url}
									/>
								</Center>

							)}
							<DetailsRow
								label={commonStrings.fields.name}
								value={item.name}
							/>
							<DetailsRow
								label={commonStrings.fields.email}
								value={item.email}
							/>
							<DetailsRow
								label={commonStrings.fields.phone}
								value={item.phone || ""}
							/>
							<DetailsRow
								label={commonStrings.fields.cpf}
								value={item.cpf || ""}
							/>
						</VStack>
					)}
				/>
			</Section>
		</Center>
	);
};

export default observer(Details);
