import React from "react";
import {
	Flex,
	Button,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import spacing from "../../themes/spacing";

interface IProps {
	onCancel: () => void;
	onGo: () => void;
	loading?: boolean;
	hasEditor?: boolean;
	goButtonText?: string;
}
export const ButtonEditor: React.FC<IProps> = (props) => {

	const {
		onGo,
		onCancel,
		loading,
		hasEditor,
		goButtonText,
	} = props;

	return (
		<Flex
			flexDir={{base: "column", xl: "row"}}
			alignItems="center"
			justifyContent="space-between"
		>
			<Button
				mt={spacing.s4}
				width={{base: "100%", xl: "48%"}}
				onClick={onGo}
				isLoading={loading}
			>
				{goButtonText || strings.common.confirm(hasEditor)}
			</Button>
			<Button
				mt={spacing.s4}
				colorScheme="primary"
				onClick={onCancel}
				variant="outline"
				width={{base: "100%", xl: "48%"}}
			>
				{strings.common.cancel}
			</Button>
		</Flex>
	);
};
