import React from "react";
import { observer } from "mobx-react-lite";

import { theme } from "../themes";
import { ChakraProvider } from "@chakra-ui/react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Auth } from "./Auth";
import Dashboard from "./Dashboard";
import Login  from "./Login";
import Recovery  from "./Recovery";
import { GlobalStoreProvider } from "../contexts/useGlobalContext";

const App = observer(() => (
	<ChakraProvider theme={theme}>
		<GlobalStoreProvider>
			<BrowserRouter>
				<Switch>
					<Route
						exact
						path="/"
						component={Auth}
					/>
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/login" component={Login} />
					<Route path="/recovery" component={Recovery} />
					<Route path="/recovery" component={Recovery} />
				</Switch>
			</BrowserRouter>
		</GlobalStoreProvider>
	</ChakraProvider>
));

export default App;
