import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import Details from "./Details";

const Reservations: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/reservations" component={Table} />
		<Route
			path="/dashboard/reservations/details/:id"
			component={Details}
		/>
	</Switch>
);

export default observer(Reservations);
