import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Stack, Button, Center } from "@chakra-ui/react";
import moment from "moment";
import { Section, DetailsRow, Loading } from "~/components";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";
import Store from "./store";

interface IParams {
	id: string;
}

const Details: React.FC = () => {
	const pageStrings = strings.reservations.details;
	const { dialog } = useGlobalStore();

	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(id));
	const history = useHistory();

	const onSeeUser = () => {
		if (store.reservation.value) {
			history.push(
				`/dashboard/users/edit/${store.reservation.value.user.id}`,
			);
		}
	};

	const onSeeProduct = () => {
		if (store.reservation.value) {
			history.push(
				`/dashboard/products/edit/${store.reservation.value.product.id}`,
			);
		}
	};

	const onSuccess = () => {
		history.goBack();
	};

	const onRemoveReservation = async () => {
		await store.removeReservation(onSuccess);
		dialog.closeDialog();
	};

	const openRemoveReservationDialog = () => {
		dialog.showDialog({
			title: strings.reservations.removeReservation.dialog.title,
			closeOnOverlayClick: true,
			buttons: [
				{
					title: strings.reservations.removeReservation.dialog.remove,
					onPress: onRemoveReservation,
					buttonProps: { bg: "red.500" },
				},
				{
					title: strings.reservations.removeReservation.dialog.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	if (store.loader.isLoading || !store.reservation.value) {
		return (
			<Center mt={spacing.s10}>
				<Loading />
			</Center>
		);
	}

	return (
		<Flex
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
			w="100%"
			p={{ base: 0, sm: 16 }}
		>
			<Section title={pageStrings.sections.reservation.title}>
				<Stack
					w="100%"
					justifyContent="center"
					direction={{ base: "column", md: "row" }}
					spacing={spacing.s8}
				>
					<DetailsRow
						label={pageStrings.sections.reservation.date}
						value={moment(store.reservation.value.createdAt).format(
							strings.moment.date,
						)}
					/>
					<DetailsRow
						label={pageStrings.sections.reservation.productName}
						value={store.reservation.value.product.title}
					/>
					<DetailsRow
						label={pageStrings.sections.reservation.size}
						value={store.reservation.value.size}
					/>
					{store.reservation.value.removedAt && (
						<DetailsRow
							label={pageStrings.sections.reservation.removalDate}
							value={moment(
								store.reservation.value.removedAt,
							).format(strings.moment.date)}
						/>
					)}
				</Stack>
			</Section>
			<Section title={pageStrings.sections.user.title}>
				<Stack
					w="100%"
					justifyContent="center"
					direction={{ base: "column", md: "row" }}
					spacing={spacing.s8}
				>
					<DetailsRow
						label={pageStrings.sections.user.name}
						value={store.reservation.value.user.name}
					/>
					<DetailsRow
						label={pageStrings.sections.user.email}
						value={store.reservation.value.user.email}
					/>
					<DetailsRow
						label={pageStrings.sections.user.phone}
						value={
							store.reservation.value.user.phone ||
						"Não informado"
						}
					/>
				</Stack>
			</Section>
			<Section title={pageStrings.sections.actions.title}>
				<Stack
					w="100%"
					justifyContent="center"
					direction={{ base: "column", md: "row" }}
					spacing={spacing.s8}
				>
					<Button onClick={onSeeUser}>
						{pageStrings.sections.actions.seeUser}
					</Button>
					<Button onClick={onSeeProduct}>
						{pageStrings.sections.actions.seeProduct}
					</Button>
					{!store.reservation.value.removedAt && (
						<Button onClick={openRemoveReservationDialog}>
							{pageStrings.sections.actions.removeReservation}
						</Button>
					)}
				</Stack>
			</Section>
		</Flex>
	);
};

export default observer(Details);
