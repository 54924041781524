import { makeAutoObservable } from "mobx";
import { LoaderShelf, ModelShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";

export default class Store {

	public loader = new LoaderShelf();
	public modelShelf: ModelShelf<api.User>;

	constructor(id: string) {
		this.modelShelf = new ModelShelf(id, api.getUser);
		makeAutoObservable(this);
	}
}
